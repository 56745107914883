/* Box sizing rules */
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

address {
  font-style: normal;
}

/* Add the correct font weight in Chrome, Edge, and Safari */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Prevent `sub` and `sup` elements from affecting the line height in all browsers. */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a[class] {
  text-decoration: none;
  color: currentColor;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
}

/* =======================Form ========================== */
/* Inherit fonts for inputs and buttons */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

/* Correct the inability to style clickable types in iOS and Safari */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/* Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* Restore the focus styles unset by the previous rule.*/
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/* Add the correct vertical alignment in Chrome, Firefox, and Opera.*/
progress {
  vertical-align: baseline;
}

/* Remove the default vertical scrollbar in IE 10+ / Stop resizing */
textarea {
  overflow: auto;
  resize: none;
}

/* Correct the cursor style of increment and decrement buttons in Chrome.*/
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
*/
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*Remove the inner padding in Chrome and Safari on macOS.*/
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* =======================Interactive ========================== */
/* Add the correct display in Edge, IE 10+, and Firefox.*/
details {
  display: block;
}

/* Add the correct display in all browsers.*/
summary {
  display: list-item;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}